<template>
  <span :class="className" :style="cssVars" @click="$emit('click')" @mouseover="$emit('mouseover')" @mouseleave="$emit('mouseleave')">&nbsp;</span>
</template>

<script>
/**
 * Icon Vue component
 *
 * See also templates/elements/icon for the Twig implementation.
 * Add new icons in templates/elements/icon/assets.
 */

export default {
  name: 'Icon',
  props: {
    icon: {
      default: 'duck-light',
    },
    size: {
      default: 'l',
    }
  },
  computed: {
    className() {
      return 'icon-element icon-size-' + this.size + ' ' + this.icon;
    },
    extended() {
      return this.icon.startsWith('x-');
    },
    iconAssetUrl() {
      if (this.extended) {
        return '/assets/icons/extended/' + this.icon.substring(2) + '.svg';
      } else {
        return '/assets/icons/' + this.icon + '.svg';
      }
    },
    cssVars() {
      return {
        'mask-image': "url('" + this.iconAssetUrl + "')",
        '-webkit-mask-image': "url('" + this.iconAssetUrl + "')",
      }
    }
  }
}
</script>
